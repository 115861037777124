* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sabre W90 Regular;
}

@font-face {
  font-family: 'YourFontName';
  /* Choose a name for your font */
  src: url('./Assets/arial-font/arial.ttf') format('woff2'),
    /* Add all necessary file formats */
    url('./Assets/arial-font/arial.ttf') format('woff');
  /* Add more src lines for additional formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sabre W90 Regular";
  src: url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.eot");
  src: url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.svg#Sabre W90 Regular")format("svg");
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  height: 66px;
}

nav ul {

  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 1rem;

}

nav ul li {
  list-style: none;
}

.headerul li {
  list-style: none;
}

.logo {
  width: 33%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
}

.logotop {
  padding-top: 10px;
}

.icons {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
}

.cpicons {
  gap: 20px;
  padding-top: 15px;
}

.logo h2 {
  font-family: Sabre W90 Regular;
  font-weight: 800;


}

.menu {
  list-style: none;
  display: flex;
  gap: 30px;
}

.menu li {
  margin: 0;
  padding: 0;
}


footer {
  border-top: 1px solid #D9D9D9;
  padding-left: 50px;
  padding-right: 50px;
}

.footer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-header-left p {
  font-size: 15px;
  font-family: 'YourFontName';

}

.footer-header-right {
  text-align: right;
  font-family: 'YourFontName';

}

.footer-header-right p {
  font-family: 'YourFontName';

}

.footer-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 400;
  margin-top: 50px;
}

.footer-list ul {
  font-size: 15px;
}

.footer-list ul li {
  list-style: none;
  margin-top: 10px;
  font-size: 13px;
  font-family: 'YourFontName';


}

.footer-input {
  width: 90%;
  height: 46px;
  border: 1px solid #D9D9D9;
  /* background-color: aqua; */
}

.footer-input input {
  width: 80%;
  height: 100%;
  border: none;
  background: none;
  font-size: 10px;
  font-weight: 400;
  padding-left: 30px;
}

.footer-input button {
  /* width: 80%; */
  height: 100%;
  border: none;
  background: none;
  border-left: 1px solid #D9D9D9;
  width: 20%;
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.login {
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
  padding-top: 100px;
}

.login-form {
  width: 550px;
  height: 393px;
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.login h1 {
  font-size: 36px;
  font-weight: 400;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.login-form input {
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  border-bottom: 1px solid black;
  font-size: 12px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.login-form button {
  Width:
    100%;
  Height:
    60px;
  background-color: black;
  color: white;
  font-family: 'YourFontName';
  font-size: 13px;


}

.login-form p {
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.singup-form {
  height: 528px;
}

.banner {
  height: 100vh;
  background-image: url(./Assets/image\ 8.svg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-left: 30px;
  padding-right: 30px;
}

.home-category {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
  gap: 20px;
}

.home-category1 {
  width: 50%;



}

.home-category-img {
  height: 850px;
  background-image: url(./Assets/image\ 4.svg);
  background-position: center;
  background-size: cover;
  transition: background-image 0.3s ease;

}

.home-category-img:hover {
  background-image: url(./Assets/image\ 5.svg);

}

.home-category-p {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}

.home-category-img2 {
  height: 850px;
  background-image: url(./Assets/image\ 5.svg);
  background-position: center;
  background-size: cover;

  transition: background-image 0.3s ease;
}

.home-category-img2:hover {
  background-image: url(./Assets/image\ 4.svg);

}

.tshirt-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tshirt-cards p {
  font-size: 15px;
  font-weight: 400;
}

.tshirt-cards h2 {
  font-size: 20px;
  font-weight: 400;
  /* margin-top: 5px; */
}

.tshirt-card {
  width: 400px;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt-card2 {
  background-color: #F2EFEB;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt-card3 {
  background-color: #EFF3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt {
  display: flex;
  flex-direction: row;
}



.tshirt {
  display: flex;
  overflow-x: auto;
  width: 100%;
  /* Ensure the container takes up the full width of its parent */
}

.categorygap {
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tshirt-card {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-name {
  margin-top: auto;
  font-size: 16px;
}

.banner2 img {
  width: 100%;
}

.tshirt::-webkit-scrollbar {
  height: 0px;
  /* Adjust the height as needed */
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.discover {
  Width:
    122.44px;
  Height:
    40px;
  border: 1px solid #D9D9D9;
  background: none;
  font-size: 15px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.tshirt-card3,
.tshirt-card2,
.tshirt-card {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tshirt-card3:hover .overlay,
.tshirt-card2:hover .overlay,
.tshirt-card:hover .overlay {
  opacity: 1;
}

.add-to-cart {
  background-color: #000 !important;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.bookmark-icon {
  color: white;
  cursor: pointer;
}
.bookmark-icon h3{
  font-size: 25px;
}
.bookmark-icon h4{
  font-size: 18px;
}
.banner3 {
  height: 90vh;
  background-image: url(./Assets/image\ 25.svg);
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.banner3 h1 {
  color: white;
  font-size: 48px;
  font-weight: 400;

}

.banner3 p {
  color: white;
  font-size: 20px;
  font-weight: 400;

}

.denim-card {
  /* width: 340px; */
  /* margin-left: 0.1px; */
  margin-bottom: 20px;
}

.denim-cards {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
}

.denim-card p {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;

}

.denim-cards {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-right: 50px;
}

.denim-card {
  width: 20%;

  box-sizing: border-box;
  /* Include padding and border in the width */
}

.denim-card img {
  width: 100%;
}

.denim-card p {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .denim-card {
    width: calc(33.33% - 20px);
    /* Show 3 cards in a row for small laptops */
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .denim-cards {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }

  .denim-card {
    width: calc(50% - 20px);
    /* Show 2 cards in a row for mobile devices */
    margin-right: 10px;
  }

  .banner3 h1 {
    font-size: 35px;

  }
}

.productinfo {
  display: flex;
  flex-direction: row;

  padding-top: 50px;
  padding-bottom: 50px;
}

.productinfo-left,
.productinfo-right {
  width: 50%;
  /* height: 500px; */
}

.productinfo-right-div {
  width: 80%;
  height: 100%;
  border: 1px solid #EAEAEA;
  padding: 20px;


}

.productinfo-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.productinfo-right-bookmark {
  display: flex;
  flex-direction: row;
  gap: 20px;

}

.productslide-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* Adjust as needed */
  align-items: center;
  overflow-x: auto;
  /* Set to 'scroll' if you want a scrollbar to always appear */
  white-space: nowrap;
  /* Prevent wrapping to the next line */
  margin-left: 15px;
}

.productslide-card {
  margin-right: 5px;
}

.productslide-card p {
  margin-top: 10px;
}

.productslide-cards::-webkit-scrollbar {
  height: 0;
  /* Adjust the height as needed */
}

.banner-scroller {
  /* background-size: contain; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: bottom;
  /* height: 700px; */
  /* padding-top: 200px; */
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RELEASE {
  Width:
    403px;
  Height:
    229px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 25px;
  
}

.RELEASE h1 {
  text-align: center;
  font-size: 20px;
  font-size: 400;

}

.RELEASE p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.RELEASE button {
  Width:
    236.63px;
  Height:
    45px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-size: 400;
}

.sideimg {
  /* width: 100%; */
  display: flex;
  /* justify-content: center; */
  overflow-x: auto;
}
.sideimg-div{
  width: 100% ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideimg img {
  width: 20%;
}

.arrow {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;

  border-bottom: 1px solid rgb(211, 211, 211);
  padding-top: 10px;
  padding-bottom: 15px;
}

.sizeing {
  border-bottom: 1px solid rgb(211, 211, 211);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.addbutton {
  Width:
    216px;
  Height:
    43px;
  background-color: black;
  color: white;

}

.cart-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
}

.cart-section-left {
  width: 50%;

}

.cart-section-left p {
  font-family: 'YourFontName';

}

.cart-section-right {
  width: 35%;

}

.btns {
  width: 80%;


  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btns button {
  height: 42px;
  border-radius: 4px;
  width: 45%;
  border: none;
}

.shopay {
  background-color: rgba(90, 49, 244, 1);
}

.gpay {
  background-color: black;
}

.lines {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

}

.line {
  height: 1px;
  width: 100%;
  background-color: #D9D9D9;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Shipping {
  display: flex;
  flex-direction: column;
}

.Shipping input {
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';

}

label {
  font-family: 'YourFontName';
}

.coupon {
  font-family: 'YourFontName';

}

.Shipping-ips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  font-family: 'YourFontName';

}

.Shipping-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'YourFontName';


}

.Shipping-info input {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';

}

.conshop {
  Width:
    200px;
  Height:
    40px;
  background-color: black;
  color: white;
  font-size: 13px;
  font-weight: 400;
  float: right;
  font-family: 'YourFontName';

}

.RELEAS {
  display: flex;
  flex-direction: column;
  font-family: 'YourFontName';
}

.cartright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.RELEAS h3 {
  font-size: 25px;
  font-weight: 500;
  font-family: 'YourFontName';

}

.RELEAS p {
  font-size: 12px;
  font-weight: 500;
  font-family: 'YourFontName';

}

.cartright {}

.code {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.code input {
  width: 90%;
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';


}

.code button {
  Width:
    72.16px;
  Height:
    31.58px;
  border: 1px solid rgba(51, 51, 51, 1);
  background: none;

}

.Subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Subtotal p {
  font-family: 'YourFontName';
}

.s {
  font-weight: 500;
  font-family: "Sabre W90 Regular" !important;

}

.cartlogo {
  margin-left: -22px;
}

.personal-info h1 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 20px;
}

.personal-info h2 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
}

.personal-info p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 125, 126, 1);
}

.sidebar ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: rgba(128, 125, 126, 1);
  height: 44px;
  Width:
    295px;
  Height:
    44px;
  font-weight: 400;

  padding-left: 20px;
}

.sidebar-active {
  background-color: rgba(246, 246, 246, 1);
  border-left: 1px solid black;
}
.sidebar-activenew {
  background-color: rgba(246, 246, 246, 1);
  padding: 10px 10px;
}

.acc {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
}

.orders ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orders ul li {
  list-style: none;
  font-family: 'YourFontName';

}

.sidebar {
  width: 30%;
}

.orders {
  width: 70%;

}

.orderinqq {
  height: 135px;
  width: 100%;
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
}

.orderinqq-left,
.orderinqq-right {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;


}

.orderinqq-left h2 {
  font-size: 20px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.orderinqq-left p,
.orderinqq-right p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(190, 188, 189, 1);
  font-family: 'YourFontName';

}

.orderinqq-right {
  width: 50%;
  text-align: right;
}

.orderinqq-left p span,
.orderinqq-right p span {
  color: black !important;
  font-family: 'YourFontName';

}

.ordered-product {
  padding-top: 20px;
  padding-bottom: 20px;

}

.ordered-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ordered-product-detail {
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.ordered-product-detail h3 {

  font-size: 16px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.ordered-product-detail p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(190, 188, 189, 1);
  font-family: 'YourFontName';

}

.ordered-product-detail p span {
  color: black;
  font-family: 'YourFontName';

}

.ordered-product-button {
  float: inline-end;
}

.ordered-product-button button {
  width: 130px;
  height: 49px;
  background-color: black;
  color: white;
  border-radius: 12px;
}

.ordered-products {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media(max-width:768px) {
  .banner {
    height: 50vh;
    margin-bottom: 50px;
  }

  .home-category {
    display: flex;
    flex-direction: column;
  }

  .home-category1 {
    width: 100%;
  }

  .home-category-img,
  .home-category-img2 {
    width: 100%;
    height: 650px !important;
  }

  .footer-header {
    flex-direction: column;
    gap: 20px;

  }

  .footer-header-right {
    text-align: left;

  }

  .footer-input {
    width: 100%;
  }

}

@media(max-width:550px) {

  .home-category-img,
  .home-category-img2 {
    width: 100%;
    height: 371.51px !important;
  }
}

.accodian {
  display: none;
}

/* Accordion.css */
.accordion {
  background-color: #ffffff;

  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  border-bottom: 1px solid rgba(215, 215, 215, 1);
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.show {
  display: block;
}

@media(max-width:768px) {
  .footer-list {
    display: none;
  }

  .accodian {
    display: block;
  }

}

@media(max-width:1280px) {
  nav ul {
    gap: 15px;

  }
}

.mobilenav {
  height: 100px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 20px; */

  padding-right: 20px;
}

.mobilenav ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.mobilenav ul li {
  list-style: none;
}

.mobilenav {
  display: none;
}

.mbnav {
  padding-left: -2;
}

@media(max-width:1080px) {
  nav {
    display: none;
  }

  .mobilenav {
    display: flex;
  }
}

.slider-div {
  margin-top: 30%;
  padding-top: 5px;
  width: 352px;
  Height:
    80px;
  background-color: rgba(255, 255, 255, 1);
}

.slider-div-img,
.slider-div-chng {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

}

.slider-div-img p {
  font-size: 12px;
  font-weight: 400;
  font-family: 'YourFontName';
}

.wishlists {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 50px;
  overflow-x: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.wishlists::-webkit-scrollbar {
  height: 0px;
  /* Adjust the height as needed */
}

.wishlist {
  width: 206px;

}

.wishlist-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist h3 {
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.wishlist h4 {
  font-size: 13px;
  font-weight: 700;
  font-family: 'YourFontName';

}

.DENIM {
  width: 100%;
  height: 31px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
}

.DENIM p {
  font-family: 'YourFontName';

}

.wishlists-div {
  display: flex;
  justify-content: center;

}

.DENIM1 {
  width: 100%;
  height: 31px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-top: 4px;


}

.ShareWishlist {
  font-size: 36px;
  font-weight: 400;
}

.socialicons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media(max-width:550px) {
  .RELEASE {
    width: 80%;
  }
}

@media(max-width:768px) {
  .productinfo {
    flex-direction: column;
  }

  .productinfo-left,
  .productinfo-right {
    width: 100%;
  }
}

@media(max-width:1200px) {
  .cart-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cart-section-right {
    width: 40%;
  }
}

@media(max-width:1200px) {
  .cart-section {
    flex-direction: column;
    gap: 50px;
  }

  .cart-section-left,
  .cart-section-right {
    width: 100%;
  }
}

@media(max-width:500px) {
  .cartright {
    flex-direction: column;
    gap: 20px;
  }
}

@media(max-width:900px) {
  .sidebar {
    display: none;
  }

  .orders {
    width: 100%;
  }
}

@media(max-width:650px) {
  .orderinqq {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;

  }

  .orderinqq-right,
  .orderinqq-left {
    text-align: left;
    width: 100%;
  }

}

@media(max-width:550px) {

  .ordered-product,
  .ordered-products {
    flex-direction: column;
    width: 100%;

  }

  .ordered-product-button button,
  .ordered-product-button {
    width: 100%;
  }

  .slider-div {
    margin-top: 230px !important;
    width: 100% !important;
  }
}

.none {
  text-decoration: none;
  color: black;
}

@media(max-width:650px) {
  .login-form {
    width: 80%;
  }
}

.about {}

.about-heading {}

body {
  overflow-x: hidden;
}

.topnav {
  background-color: black;
  height: 30px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  /* padding-left: 45%; */
  text-align: center;
}

.topnav p {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  margin: 0;
}

.lang {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* Align to the right */
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.lang h6 {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: 0;
}

.lang img {
  margin-right: 5px;
  /* Add some spacing between the image and text */
}

nav ul li {
  list-style: none;
}

nav ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}


@media(max-width: 768px) {
  .topnav {
    /* padding-left: 124px; */
  }

  .topnav p {
    font-size: 8px;
  }
}

@media(max-width: 400px) {
  .topnav {
    /* padding-left: 20px; */
  }
}

.Imagesize {
  width: 300px;
  height: 300px;
}


@media(max-width: 768px) {
  .Imagesize {
    width: 250px;
    height: 300px;
  }
}

@media(max-width: 400px) {
  .Imagesize {
    width: 150px;
    height: 200px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sabre W90 Regular;
}

@font-face {
  font-family: 'YourFontName';
  /* Choose a name for your font */
  src: url('./Assets/arial-font/arial.ttf') format('woff2'),
    /* Add all necessary file formats */
    url('./Assets/arial-font/arial.ttf') format('woff');
  /* Add more src lines for additional formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sabre W90 Regular";
  src: url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.eot");
  src: url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/29370c9baa72927de8a67801c67e8952.svg#Sabre W90 Regular")format("svg");
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  height: 66px;
}

nav ul {

  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 1rem;

}

nav ul li {
  list-style: none;
}

.logo {
  width: 33%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
}

.icons {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
}

.cpicons {
  gap: 20px;
}

.logo h2 {
  font-family: Sabre W90 Regular;
  font-weight: 800;


}

.menu {
  list-style: none;
  display: flex;
  gap: 30px;
}

.menu li {
  margin: 0;
  padding: 0;
}


footer {
  border-top: 1px solid #D9D9D9;
  padding-left: 50px;
  padding-right: 50px;
}

.footer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-header-left p {
  font-size: 15px;
  font-family: 'YourFontName';

}

.footer-header-right {
  text-align: right;
  font-family: 'YourFontName';

}

.footer-header-right p {
  font-family: 'YourFontName';

}

.footer-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 400;
  margin-top: 50px;
}

.footer-list ul {
  font-size: 15px;
}

.footer-list ul li {
  list-style: none;
  margin-top: 10px;
  font-size: 13px;
  font-family: 'YourFontName';


}

.footer-input {
  width: 90%;
  height: 46px;
  border: 1px solid #D9D9D9;
  /* background-color: aqua; */
}

.footer-input input {
  width: 80%;
  height: 100%;
  border: none;
  background: none;
  font-size: 10px;
  font-weight: 400;
  padding-left: 30px;
}

.footer-input button {
  /* width: 80%; */
  height: 100%;
  border: none;
  background: none;
  border-left: 1px solid #D9D9D9;
  width: 20%;
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.login {
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
}

.login-form {
  width: 550px;
  height: 393px;
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.login h1 {
  font-size: 36px;
  font-weight: 400;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.login-form input {
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  border-bottom: 1px solid black;
  font-size: 12px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.login-form button {
  Width:
    100%;
  Height:
    60px;
  background-color: black;
  color: white;
  font-family: 'YourFontName';
  font-size: 13px;


}

.login-form p {
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.singup-form {
  height: 528px;
}

.banner {
  height: 100vh;
  background-image: url(./Assets/image\ 8.svg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.home-category {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
  gap: 20px;
}

.home-category1 {
  width: 50%;



}

.home-category-img {
  height: 850px;
  background-image: url(./Assets/image\ 4.svg);
  background-position: center;
  background-size: cover;
}

.home-category-p {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}

.home-category-img2 {
  height: 850px;
  background-image: url(./Assets/image\ 5.svg);
  background-position: center;
  background-size: cover;
}

.tshirt-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tshirt-cards p {
  font-size: 20px;
  font-weight: 400;
}

.tshirt-cards h2 {
  font-size: 25px;
  font-weight: 400;
  /* margin-top: 5px; */
}

.tshirt-card {
  width: 360px;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt-card2 {
  background-color: #F2EFEB;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt-card3 {
  background-color: #EFF3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tshirt {
  display: flex;
  flex-direction: row;
}



.tshirt {
  display: flex;
  overflow-x: auto;
  width: 100%;
  /* Ensure the container takes up the full width of its parent */
}

.tshirt-card {
  flex: 0 0 auto;
  width: 300px;
  /* Adjust the width as needed */
  margin-right: 10px;
  /* Adjust as needed for spacing between cards */
}

.banner2 img {
  width: 100%;
}

.tshirt::-webkit-scrollbar {
  height: 0px;
  /* Adjust the height as needed */
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.discover {
  Width:
    122.44px;
  Height:
    40px;
  border: 1px solid #D9D9D9;
  background: none;
  font-size: 15px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.tshirt-card3,
.tshirt-card2,
.tshirt-card {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tshirt-card3:hover .overlay,
.tshirt-card2:hover .overlay,
.tshirt-card:hover .overlay {
  opacity: 1;
}

.add-to-cart {
 
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid rgb(0, 0, 0);

}
.add-to-cart:hover{
  background: none !important;
  border: 2px solid white;
  color: white;
}
.bookmark-icon {
  color: white;
  cursor: pointer;
  /* Add styles for the bookmark icon, you might want to use an actual icon font or an SVG here */
}

.banner3 {
  height: 90vh;
  background-image: url(./Assets/image\ 25.svg);
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.banner3 h1 {
  color: white;
  font-size: 48px;
  font-weight: 400;

}

.banner3 p {
  color: white;
  font-size: 20px;
  font-weight: 400;

}

.denim-card {
  width: 20%;

}

.denim-cards {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
}

.denim-card p {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;

}

.denim-cards {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-right: 50px;
}

.denim-card {
  width: 20%;

  box-sizing: border-box;
  /* Include padding and border in the width */
}

.denim-card img {
  width: 100%;
}

.denim-card p {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
}
.denim-card h3{
  margin-top: 10px;

  font-size: 20px;

}

@media (max-width: 1200px) {
  .denim-card {
    width: calc(33.33% - 20px);
    /* Show 3 cards in a row for small laptops */
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .denim-cards {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }

  .denim-card {
    width: calc(50% - 20px);
    /* Show 2 cards in a row for mobile devices */
    margin-right: 10px;
  }

  .banner3 h1 {
    font-size: 35px;

  }
}

.productinfo {
  display: flex;
  flex-direction: row;

  padding-top: 50px;
  padding-bottom: 50px;
}

.productinfo-left,
.productinfo-right {
  width: 50%;
  /* height: 500px; */
}

.productinfo-right-div {
  width: 80%;
  height: 100%;
  border: 1px solid #EAEAEA;
  padding: 20px;


}

.productinfo-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.productinfo-right-bookmark {
  display: flex;
  flex-direction: row;
  gap: 20px;

}

.productslide-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* Adjust as needed */
  align-items: center;
  overflow-x: auto;
  /* Set to 'scroll' if you want a scrollbar to always appear */
  white-space: nowrap;
  /* Prevent wrapping to the next line */
  margin-left: 15px;
  gap: 30px;
}

.productslide-card {
  margin-right: 5px;
}

.productslide-card p {
  margin-top: 10px;
}

.productslide-cards::-webkit-scrollbar {
  height: 0;
  /* Adjust the height as needed */
}

.banner-scroller {
  /* background-size: contain; */
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;

  background-size: contain;
  /* background-size: cover; */
  background-position: bottom;
  /* height: 700px; */
  padding-top: 100px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RELEASE {
  Width:
    403px;
  Height:
    229px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 25px;
}

.RELEASE h1 {
  text-align: center;
  font-size: 20px;
  font-size: 400;

}

.RELEASE h2{
  font-size: 15px;
}
.RELEASE p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.RELEASE button {
  Width:
    236.63px;
  Height:
    45px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-size: 400;
}

.sideimg {
  /* width: 100%; */
  display: flex;
  /* justify-content: center; */
}

.sideimg img {
  width: 20%;
}

.image-container {
  display: inline-block;
  margin: 10px;
  border: 1px solid black;
  border-radius: 20px;
}

.image-container img {
  height: 80px;
  width: 80px;
  border-radius: 20px;
}

.arrow {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;

  border-bottom: 1px solid rgb(211, 211, 211);
  padding-top: 10px;
  padding-bottom: 15px;
}

.sizeing {
  border-bottom: 1px solid rgb(211, 211, 211);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.addbutton {
  Width:
    216px;
  Height:
    43px;
  background-color: black;
  color: white;

}

.cart-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
}

.cart-section-left {
  width: 50%;

}

.cart-section-left p {
  font-family: 'YourFontName';

}

.cart-section-right {
  width: 35%;

}

.btns {
  width: 80%;


  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btns button {
  height: 42px;
  border-radius: 4px;
  width: 45%;
  border: none;
}

.shopay {
  background-color: rgba(90, 49, 244, 1);
}

.gpay {
  background-color: black;
}

.lines {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

}

.line {
  height: 1px;
  width: 100%;
  background-color: #D9D9D9;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Shipping {
  display: flex;
  flex-direction: column;
}

.Shipping input {
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';

}

label {
  font-family: 'YourFontName';
}

.coupon {
  font-family: 'YourFontName';

}

.Shipping-ips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  font-family: 'YourFontName';

}

.Shipping-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'YourFontName';


}

.Shipping-info input {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';

}

.conshop {
  Width:
    200px;
  Height:
    40px;
  background-color: black;
  color: white;
  font-size: 13px;
  font-weight: 400;
  float: right;
  font-family: 'YourFontName';

}
.conshop:hover{
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.RELEAS {
  display: flex;
  flex-direction: column;
  font-family: 'YourFontName';

}

.cartright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.RELEAS h3 {
  font-size: 12px;
  font-weight: 700;
  font-family: 'YourFontName';

}

.RELEAS p {
  font-size: 12px;
  font-weight: 500;
  font-family: 'YourFontName';

}

.cartright {}

.code {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.code input {
  width: 90%;
  border: none;
  border-bottom: 1px solid black;
  font-family: 'YourFontName';


}

.code button {
  Width:
    72.16px;
  Height:
    31.58px;
  border: 1px solid rgba(51, 51, 51, 1);
  background: none;

}

.Subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Subtotal p {
  font-family: 'YourFontName';
}

.s {
  font-weight: 500;
  font-family: "Sabre W90 Regular" !important;

}

.cartlogo {
  margin-left: -22px;
}

.personal-info h1 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 20px;
}

.personal-info h2 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
}

.personal-info p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 125, 126, 1);
}

.sidebar ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: rgba(128, 125, 126, 1);
  height: 44px;
  Width:
    295px;
  Height:
    44px;
  font-weight: 400;

  padding-left: 20px;
}

.sidebar-active {
  background-color: rgba(246, 246, 246, 1);
  border-left: 1px solid black;
}
.sidebar-activenew {
  background-color: rgba(246, 246, 246, 1);
  padding: 10px 10px;
}

.acc {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
}

.orders ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orders ul li {
  list-style: none;
  font-family: 'YourFontName';

}

.sidebar {
  width: 30%;
}

.orders {
  width: 70%;

}

.orderinqq {
  height: 135px;
  width: 100%;
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
}

.orderinqq-left,
.orderinqq-right {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;


}

.orderinqq-left h2 {
  font-size: 20px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.orderinqq-left p,
.orderinqq-right p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(190, 188, 189, 1);
  font-family: 'YourFontName';

}

.orderinqq-right {
  width: 50%;
  text-align: right;
}

.orderinqq-left p span,
.orderinqq-right p span {
  color: black !important;
  font-family: 'YourFontName';

}

.ordered-product {
  padding-top: 20px;
  padding-bottom: 20px;

}

.ordered-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ordered-product-detail {
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.ordered-product-detail h3 {

  font-size: 16px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.ordered-product-detail p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(190, 188, 189, 1);
  font-family: 'YourFontName';

}

.ordered-product-detail p span {
  color: black;
  font-family: 'YourFontName';

}

.ordered-product-button {
  float: inline-end;
}

.ordered-product-button button {
  width: 130px;
  height: 49px;
  background-color: black;
  color: white;
  border-radius: 12px;
}

.ordered-products {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media(max-width:768px) {
  .banner {
    height: 50vh;
    margin-bottom: 30px;
  }

  .home-category {
    display: flex;
    flex-direction: column;
  }

  .home-category1 {
    width: 100%;
  }

  .home-category-img,
  .home-category-img2 {
    width: 100%;
    height: 650px !important;
  }

  .footer-header {
    flex-direction: column;
    gap: 20px;

  }

  .footer-header-right {
    text-align: left;

  }

  .footer-input {
    width: 100%;
  }

}

@media(max-width:550px) {

  .home-category-img,
  .home-category-img2 {
    width: 100%;
    height: 371.51px !important;
  }
}

.accodian {
  display: none;
}

/* Accordion.css */
.accordion {
  background-color: #ffffff;

  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  border-bottom: 1px solid rgba(215, 215, 215, 1);
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.show {
  display: block;
}

@media(max-width:768px) {
  .footer-list {
    display: none;
  }

  .accodian {
    display: block;
  }

}

@media(max-width:1280px) {
  nav ul {
    gap: 15px;

  }
}

.mobilenav {
  height: 100px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 20px; */

  padding-right: 20px;
}

.mobilenav ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.mobilenav ul li {
  list-style: none;
}

.mobilenav {
  display: none;
}

.mbnav {
  padding-left: -2;
}

@media(max-width:1080px) {
  nav {
    display: none;
  }

  .mobilenav {
    display: flex;
  }
}

.slider-div {
  margin-top: 30%;
  padding-top: 5px;
  width: 352px;
  Height:
    80px;
  background-color: rgba(255, 255, 255, 1);
}

.slider-div-img,
.slider-div-chng {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

}

.slider-div-img p {
  font-size: 12px;
  font-weight: 400;
  font-family: 'YourFontName';
}

.wishlists {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 50px;
  overflow-x: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.wishlists::-webkit-scrollbar {
  height: 0px;
  /* Adjust the height as needed */
}

.wishlist {
  width: 206px;

}

.wishlist-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist h3 {
  font-size: 13px;
  font-weight: 400;
  font-family: 'YourFontName';

}

.wishlist h4 {
  font-size: 13px;
  font-weight: 700;
  font-family: 'YourFontName';

}

.DENIM {
  width: 100%;
  height: 31px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
}

.DENIM p {
  font-family: 'YourFontName';

}

.wishlists-div {
  display: flex;
  justify-content: center;

}

.DENIM1 {
  width: 100%;
  height: 31px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-top: 4px;


}

.ShareWishlist {
  font-size: 36px;
  font-weight: 400;
}

.socialicons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media(max-width:550px) {
  .RELEASE {
    width: 80%;
  }
}

@media(max-width:768px) {
  .productinfo {
    flex-direction: column;
  }

  .productinfo-left,
  .productinfo-right {
    width: 100%;
  }
}

@media(max-width:1200px) {
  .cart-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cart-section-right {
    width: 40%;
  }
}

@media(max-width:1200px) {
  .cart-section {
    flex-direction: column;
    gap: 50px;
  }

  .cart-section-left,
  .cart-section-right {
    width: 100%;
  }
}

@media(max-width:500px) {
  .cartright {
    flex-direction: column;
    gap: 20px;
  }
}

@media(max-width:900px) {
  .sidebar {
    display: none;
  }

  .orders {
    width: 100%;
  }
}

@media(max-width:650px) {
  .orderinqq {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;

  }

  .orderinqq-right,
  .orderinqq-left {
    text-align: left;
    width: 100%;
  }

}

@media(max-width:550px) {

  .ordered-product,
  .ordered-products {
    flex-direction: column;
    width: 100%;

  }

  .ordered-product-button button,
  .ordered-product-button {
    width: 100%;
  }

  .slider-div {
    margin-top: 230px !important;
    width: 100% !important;
  }
}

.none {
  text-decoration: none;
  color: black;
}

@media(max-width:650px) {
  .login-form {
    width: 80%;
    padding: 20px;
  }
}

.about {
  padding-left: 50px;
  padding-right: 50px;
}

.about-heading {
  display: flex;
  flex-direction: row;
  gap: 50px;


}

.aboutheadings h1 {
  font-size: 50px;
  font-family: 'YourFontName';
  font-weight: 700;
}

.aboutheadings h2 {
  font-size: 50px;

}

.type--A {
  --line_color: #555555;
  --back_color: #FFECF6;
}

.type--B {
  --line_color: #1b1919;
  --back_color: #E9ECFF
}

.type--C {
  --line_color: #00135C;
  --back_color: #DEFFFA
}

.button {
  position: relative;
  z-index: 0;
  width: 240px;
  height: 56px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: var(--line_color);
  letter-spacing: 2px;
  transition: all .3s ease;
}

.button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.button::before,
.button::after,
.button__text::before,
.button__text::after {
  content: '';
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: var(--line_color);
  transition: all .5s ease;
}

.button::before {
  top: 0;
  left: 54px;
  width: calc(100% - 56px * 2 - 16px);
}

.button::after {
  top: 0;
  right: 54px;
  width: 8px;
}

.button__text::before {
  bottom: 0;
  right: 54px;
  width: calc(100% - 56px * 2 - 16px);
}

.button__text::after {
  bottom: 0;
  left: 54px;
  width: 8px;
}

.button__line {
  position: absolute;
  top: 0;
  width: 56px;
  height: 100%;
  overflow: hidden;
}

.button__line::before {
  content: '';
  position: absolute;
  top: 0;
  width: 150%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 300px;
  border: solid 3px var(--line_color);
}

.button__line:nth-child(1),
.button__line:nth-child(1)::before {
  left: 0;
}

.button__line:nth-child(2),
.button__line:nth-child(2)::before {
  right: 0;
}

.button:hover {
  letter-spacing: 6px;
}

.button:hover::before,
.button:hover .button__text::before {
  width: 8px;
}

.button:hover::after,
.button:hover .button__text::after {
  width: calc(100% - 56px * 2 - 16px);
}

.button__drow1,
.button__drow2 {
  position: absolute;
  z-index: -1;
  border-radius: 16px;
  transform-origin: 16px 16px;
}

.button__drow1 {
  top: -16px;
  left: 40px;
  width: 32px;
  height: 0;
  transform: rotate(30deg);
}

.button__drow2 {
  top: 44px;
  left: 77px;
  width: 32px;
  height: 0;
  transform: rotate(-127deg);
}

.button__drow1::before,
.button__drow1::after,
.button__drow2::before,
.button__drow2::after {
  content: '';
  position: absolute;
}

.button__drow1::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-60deg);
}

.button__drow1::after {
  top: -10px;
  left: 45px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(69deg);
}

.button__drow2::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-146deg);
}

.button__drow2::after {
  bottom: 26px;
  left: -40px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-262deg);
}

.button__drow1,
.button__drow1::before,
.button__drow1::after,
.button__drow2,
.button__drow2::before,
.button__drow2::after {
  background: var(--back_color);
}

.button:hover .button__drow1 {
  animation: drow1 ease-in .06s;
  animation-fill-mode: forwards;
}

.button:hover .button__drow1::before {
  animation: drow2 linear .08s .06s;
  animation-fill-mode: forwards;
}

.button:hover .button__drow1::after {
  animation: drow3 linear .03s .14s;
  animation-fill-mode: forwards;
}

.button:hover .button__drow2 {
  animation: drow4 linear .06s .2s;
  animation-fill-mode: forwards;
}

.button:hover .button__drow2::before {
  animation: drow3 linear .03s .26s;
  animation-fill-mode: forwards;
}

.button:hover .button__drow2::after {
  animation: drow5 linear .06s .32s;
  animation-fill-mode: forwards;
}

@keyframes drow1 {
  0% {
    height: 0;
  }

  100% {
    height: 100px;
  }
}

@keyframes drow2 {
  0% {
    width: 0;
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  11% {
    opacity: 1;
  }

  100% {
    width: 120px;
  }
}

@keyframes drow3 {
  0% {
    width: 0;
  }

  100% {
    width: 80px;
  }
}

@keyframes drow4 {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@keyframes drow5 {
  0% {
    width: 0;
  }

  100% {
    width: 124px;
  }
}







.container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button:not(:last-child) {
  margin-bottom: 64px;
}

.BEYOND p {
  font-size: 20px;
  font-weight: 500;
  font-family: 'YourFontName';
}

.about-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-div-left,
.about-div-right {
  width: 50%;
}

.about-div-left {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.tshirtimg {
  width: 70%;
}

.about-div-right p {
  font-size: 35px;
  font-weight: 400;
  font-family: 'YourFontName';
}

.about-section {
  background-color: #e1d8ff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about {
  background-color: white;
  padding-top: 50px;
  border-radius: 50px;
  padding-bottom: 50px;


}



.link {
  width: 10rem;
  height: 10rem;
  display: inline-block;
  font: 300 1.55rem/1.4 "Josefin Sans";
  text-transform: uppercase;
  letter-spacing: 0.1175em;
  word-spacing: 0.3em;
  text-decoration: none;
}

.link__svg {
  width: 100%;
  height: auto;
  transform-box: fill-box;
  fill: #2b3338;
  stroke: #2b3338;
  stroke-width: 0.05em;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.link__cloud {
  transform-origin: 50% 50%;
  -webkit-animation: rotate normal infinite 60s linear;
  animation: rotate normal infinite 60s linear;
  fill: rgba(255, 255, 255, 0.15);
}

.link__face,
.link__arrow {
  transform-origin: 50% 50%;
  transition: transform 0.15s cubic-bezier(0.32, 0, 0.67, 0);
}

.link:hover .link__face,
.link:hover .link__arrow {
  transform: scale(1.1);
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}

.link__arrow {
  stroke-width: 0.075em;
}

.link__text {
  -webkit-animation: rotateReverse normal infinite 20s linear;
  animation: rotateReverse normal infinite 20s linear;
  transform-origin: 50% 50%;
}

.link:hover .link__text {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.link--alt {
  font-size: 1.15rem;
  letter-spacing: 0;
  word-spacing: 0;
}

@-webkit-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateReverse {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateReverse {
  to {
    transform: rotate(-360deg);
  }
}



.cardslider {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plays {
  display: flex;
  gap: 15px;
  font-size: 20px;

}

button:hover {
  color: #000;
}

span {
  transition: all 0.7s;
  z-index: -1;
}

button .first {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 25%;
  height: 100%;
  background: #ffa500;
}

button:hover .first {
  top: 0;
  right: 0;
}

button .second {
  content: "";
  position: absolute;
  left: 25%;
  top: -100%;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

button:hover .second {
  top: 0;
  left: 50%;
}

button .third {
  content: "";
  position: absolute;
  left: 50%;
  height: 100%;
  top: 100%;
  width: 25%;
  background: #ffa500;
}

button:hover .third {
  top: 0;
  left: 25%;
}

button .fourth {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

button:hover .fourth {
  top: 0;
  left: 0;
}




.link_wrapper {
  position: relative;
}

.anch {
  display: block;
  width: 250px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #333;
  transition: all .35s;
}

.icon {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all .35s;
}

.icon svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: #000;
  transition: all .35s;
}

.anch:hover {
  width: 200px;
  border: 3px solid #000;
  background: transparent;
  color: #000;
}

.anch:hover+.icon {
  border: 3px solid #000;
  right: -25%;
}




.social {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
}

.social li {
  list-style: none;
  margin: 0 40px;
}

.social li .fa {
  font-size: 40px;
  color: #262626;
  line-height: 80px;
  transition: 0.5s;
}

.social li a {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background-color: #fff;
  text-align: center;
  transform: perspective(100px) rotate(-30deg) skew(25deg) translate(0, 0);
  transition: 0.5s;
  box-shadow: -20px 20px 10px rgb(0, 0, 0, 0.5);
}

.social li a::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  background: #b1b1b1;
  transition: 0.5s;
  transform: rotate(0deg) skewY(-45deg);
}

.social li a::after {
  content: "";
  position: absolute;
  top: 80px;
  left: -11px;
  height: 20px;
  width: 100%;
  background: #b1b1b1;
  transition: 0.5s;
  transform: rotate(0deg) skewX(-45deg);
}

.social li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px, -20px);
  box-shadow: -50px 50px 50px rgb(0, 0, 0, 0.5);
}

.social li:hover .fa {
  color: #fff;
}

.social li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px, -20px);
  box-shadow: -50px 50px 50px rgb(0, 0, 0, 0.5);
}

ul .facebook:hover a {
  background: #3b5999;
}

.social .facebook:hover a:before {
  background: #2e4a86;
}

.social .facebook:hover a:after {
  background: #4a69ad;
}

.social .twitter:hover a {
  background: #55acee;
}

.social .twitter:hover a:before {
  background: #4184b7;
}

.social .twitter:hover a:after {
  background: #4d9fde;
}

.social .google-plus:hover a {
  background: #dd4b39;
}

.social .google-plus:hover a:before {
  background: #c13929;
}

.social .google-plus:hover a:after {
  background: #e83322;
}

.social .linkedin:hover a {
  background: #0077b5;
}

.social .linkedin:hover a:before {
  background: #036aa0;
}

.social .linkedin:hover a:after {
  background: #0d82bf;
}

.social .instagram:hover a {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.social .instagram:hover a:before {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.social .instagram:hover a:after {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.link_wrapper {
  position: relative;
}

.anch {
  display: block;
  width: 250px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #333;
  transition: all .35s;
}

.icon {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all .35s;
}

.icon svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  /* fill: #;
        transition: all .35s; */
}

.anch:hover {
  width: 200px;
  border: 3px solid #000;
  background: transparent;
  color: #000;
}

.anch:hover+.icon {
  border: 3px solid #000;
  right: -25%;
}





.socialul-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00135C;
 
}

.socialul {
  position: absolute;
  /* top:50%;
        left:50%; */
  /* transform:translate(-50%,-50%); */
  margin: 0;
  padding: 0;
  display: flex;
  padding-bottom: 50px;
  margin-bottom: 100px;

}

.socialul li {
  list-style: none;
}

.socialul li a {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  color: #404040;
  margin: 0 30px;
  transition: .5s;
}

.socialul li a span {
  position: absolute;
  transition: transform .5s;
}

.socialul li a span:nth-child(1),
.socialul li a span:nth-child(3) {
  width: 100%;
  height: 3px;
  background: #404040;
}

.socialul li a span:nth-child(1) {
  top: 0;
  left: 0;
  transform-origin: right;
}

.socialul li a:hover span:nth-child(1) {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform .5s;
}

.socialul li a span:nth-child(3) {
  bottom: 0;
  left: 0;
  transform-origin: left;
}

.socialul li a:hover span:nth-child(3) {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s;
}

.socialul li a span:nth-child(2),
.socialul li a span:nth-child(4) {
  width: 3px;
  height: 100%;
  background: #404040;
}

.socialul li a span:nth-child(2) {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: bottom;
}

.socialul li a:hover span:nth-child(2) {
  transform: scale(1);
  transform-origin: top;
  transition: transform .5s;
}

.socialul li a span:nth-child(4) {
  top: 0;
  right: 0;
  transform: scale(0);
  transform-origin: top;
}

.socialul li a:hover span:nth-child(4) {
  transform: scale(1);
  transform-origin: bottom;
  transition: transform .5s;
}

.facebook:hover {
  color: #3b5998;
}

.facebook:hover span {
  background: #3b5998;
}

.twitter:hover {
  color: #1da1f2;
}

.twitter:hover span {
  background: #1da1f2;
}

.instagram:hover {
  color: #c32aa3;
}

.instagram:hover span {
  background: #c32aa3;
}

.google:hover {
  color: #dd4b39;
}

.google:hover span {
  background: #dd4b39;
}

.socialul li a .twitter {
  color: #1da1f2;
}

.socialul li a:hover:nth-child(3) {
  color: #c32aa3;
}

.socialul li a:hover:nth-child(4) {
  color: #dd4b39;
}


.title {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  letter-spacing: 1px;
  line-height: 2
}

.he {
  background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 30px;
  /* margin: 10px 0; */
}

/* styling my button */

.white-mode {
  text-decoration: none;
  padding: 7px 10px;
  background-color: #122;
  border-radius: 3px;
  color: #FFF;
  transition: .35s ease-in-out;
  position: absolute;
  left: 15px;
  bottom: 15px;
  font-family: "Montserrat";
}

.white-mode:hover {
  background-color: #FFF;
  color: rgb(0, 0, 0);
}

@media(max-width:1045px) {
  .about-heading {
    flex-direction: column;
  }

  .about-div {
    flex-direction: column;
  }

  .about-div-left {
    align-items: center;
  }

  .about-div-right,
  .about-div-left {
    width: 100%;
  }
}

@media(max-width:550px) {
  .aboutheadings h1 {
    font-size: 50px;
    font-family: 'YourFontName';
    font-weight: 700;
  }

  .aboutheadings h2 {
    font-size: 30px;

  }

  .about-heading {
    gap: 20px;
  }

  .about-div-right p {
    font-size: 20px;
    margin-top: 30px;

  }
}

.bag {
  height: 45px;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: #000;

}

.marquee-content {
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  flex-direction: row;
  gap: 100px;
  white-space: nowrap;
  animation: marquee 50s linear infinite;
}

.marquee-content p {
  font-size: 15px;
  font-family: 'YourFontName';
  font-weight: 500;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.gallery {
  column-count: 4;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  gap: 1rem;
  padding: 4rem;
}

.image {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
}

.image img {
  height: auto;
  width: 100%;
  transition: transform 0.3s ease;
}

.image:hover img {
  transform: scale(1.1);
  /* adjust scaling factor as needed */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* adjust opacity as needed */
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.overlay i {
  font-size: 100px;

}

.image:hover .overlay {
  opacity: 1;
}


/* Responsive-ness for different screen-sizes */
@media screen and (max-width: 810px) {
  .gallery {
    column-count: 3;
    --webkit-column-count: 3;
    --moz-column-count: 3;
  }
}

@media screen and (max-width: 500px) {
  .gallery {
    column-count: 2;
    --webkit-column-count: 2;
    --moz-column-count: 2;
  }
}

@media screen and (max-width: 400px) {
  .gallery {
    column-count: 1;
    --webkit-column-count: 1;
    --moz-column-count: 1;
  }
}

.new-cards {
  display: flex;
  flex-direction: row;

  justify-content: center;
  gap: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.new-card {
  width: 350px;
  height: 500px;

  box-shadow: 0 2px 7px #dfdfdf;
  border-radius: 5px;

}

.new-card-img {
  height: 55%;
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-card-img img {
  height: 70%;
}

.new-card-text {
  background-color: #FAFAFA;
  height: 45%;
  padding: 20px;
  /* font-family: "Roboto", sans-serif; */

}

.new-card-text p {
  font-size: 15px;
  color: #999;
  font-family: "Roboto", sans-serif;
}

.new-card-text h2 {
  font-size: 20px;
  color: #363636;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.new-card-text h2:hover {
  color: #fbb72c;
  font-family: "Roboto", sans-serif;
}

.new-card-text h3 {
  color: #999;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
}

.ions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 18px;
  border-top: 1px solid #999;
}

.ions i {
  font-size: 20px;
  margin-left: 10px;
  color: #999;
}

.ions i:hover {
  color: #fbb72c;

}

.ions h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fbb72c;
  font-family: "Roboto", sans-serif;


}

.ions h4 del {
  font-size: 15px;
}

.bras-btn {
  border: none;
  background: none;
  margin-right: 15px;
  font-size: 20px;
}

@media(max-width:420px) {
  .search {
    display: none !important;
  }

  .bras-btn {
    margin-right: 30px;

  }
}

.dot {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;

}

.fvt {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background-color: #F6F6F6;
  position: absolute;
  bottom: -100px;
  right: 50px;
}

.crossbutton{
  border: none;
  background-color: transparent;
  height: 25px;
  width: 25px;
}
.table-card-img { 
  width: 70px; 
  height: 70px; 
  border-radius: 12px; 
  object-fit: cover;
}

.quantitypower{
  /* top: -40px;
  right: 20px; */
  font-size: 15px;
}



.info-list { margin: 0; padding: 0; list-style-type: none; display: flex; flex-direction: column; gap: 20px;}
.info-list .info-item { padding: 12px 37px;}
.info-list .info-item-active { padding: 12px 37px; background-color: #F6F6F6; border-radius: 0 4px 4px 0; border-left: 2px solid #3C4242;}
.info-list .info-item a { display: flex; gap: 17px; text-decoration: none; color: #807D7E; font-family: 'Causten'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal;}
.info-list .info-item-active a { display: flex; gap: 17px; text-decoration: none; color: #807D7E; font-family: 'Causten'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal;}
.my-title { color: #3C4242; font-family: 'Causten'; font-size: 28px; font-style: normal; font-weight: 700; line-height: 33.5px; letter-spacing: 0.56px; margin-bottom: 20px;}
.my-sub-title { color: #3C4242; font-family: 'Causten'; font-size: 22px; font-style: normal; font-weight: 700; line-height: 33.5px; letter-spacing: 0.44px; margin-bottom: 30px;}
.my-right { width: 100%;}
.info-box { padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px solid #EDEEF2; }
.info-box-title { color: #807D7E; font-family: 'Causten'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal; margin-bottom: 8px;}
.info-box-text { color: #3C4242; font-family: 'Causten'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal; margin: 0;}
.address-box { border-radius: 12px; background: #F6F6F6; padding: 25px 43px; max-width: 360px; width: 100%;}
.address-box .address-title { color: #3C4242; font-family: 'Causten'; font-size: 20px; font-style: normal; font-weight: 700; line-height: normal; margin-bottom: 20px;}
.address-box .address-num { color: #807D7E; font-family: 'Causten'; font-size: 15px; font-style: normal; font-weight: 400; line-height: normal; margin-bottom: 20px; width: 100%;}
.home-btn { border-radius: 8px; border: 1px solid #807D7E; display: flex; padding: 8px 14px; color: #807D7E; font-family: 'Causten'; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; }
.remove-btn { color: #3C4242; font-family: 'Causten'; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal; }
.remove-btn:first-child { border-right: 1px solid #D9D9D9; padding-right: 10px; margin-right: 10px;}

@media(max-width: 768px) {
  .info-list .info-item-active { padding: 12px 20px;}
  .info-list .info-item { padding: 12px 20px;}
}

.DownloadPDF-div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DownloadPDF-div button{
  color: white;
  background-color: #000;
  padding: 15px 20px 15px 20px;
}
@media(max-width:768px){
  .socialul li a{
    width: 50px;
  height: 50px;
  }
  .socialul li a{
    line-height: 50px;

    font-size: 20px;
  margin: 0 15px;

  }
  .socialul-div{
    padding-left: 20px;
    padding-right: 20px;
  }
}
.newgallary{
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;

}
.newgallary img{
  width: 30%;
  height: 350px;
}
.selected-btn{
  background-color: #000 !important;
  color: white !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
}
.select-btn{
  padding: 12px 30px !important;
  border-radius: 10px !important;
}

@media(max-width:768px){
  .banner-scroller{
    padding-top: 50px;
    background-position: top;
  }
}

.black-btn{
  background-color: #000 !important;
}

@media(max-width:550px){
  .logo {
    width: 50%;
  }
  .table-card-img { 
    width:150px; 
    height: 150px; 
  }
  .bookmark-icon h3{
    font-size: 18px;
  }
  .bookmark-icon h4{
    font-size: 12px;
  }
  footer{
    padding-left: 30px ;
    padding-right: 30px;
  }
  .acc {
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media(max-width:768px){
  .newgallary{
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px;
  
  }
  .newgallary img{
    width: 45%;
    height: auto;

  }
  .categorygap{
    flex-direction: column;
  }
}

