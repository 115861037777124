@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




header { display: flex; justify-content: space-between; padding: 15px 80px; align-items: center; border-bottom: 1px solid #DDDBDC;}
.header-list { list-style: none; margin: 0; padding: 0; display: flex; gap: 24px;}
.header-list .list-item { position: relative; transition: all 0.5s; top:5px}

.header-list .list-item a { color:#262626; font-family: 'Montserrat', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 16px; letter-spacing: 0.2px; text-decoration: none; transition: all 0.5s; padding: 22px 0; border-bottom: 2px solid transparent; padding-bottom: 18px;}
.header-list .list-item .active { color:#262626; font-family: 'Montserrat', sans-serif; font-size: 16px; font-style: normal; font-weight: 700; line-height: 16px; letter-spacing: 0.2px; text-decoration: none; transition: all 0.5s; padding: 22px 0; border-bottom: 2px solid #262626;}
.header-list .list-item a:hover { border-bottom: 2px solid #262626; font-weight: 700;}

.social-media-list { list-style: none; margin: 0; padding: 0; display: flex; gap: 24px;}


.sub-header { display: none; justify-content: center; align-items: center; padding: 20px 0; background: #FFF; box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);}
.sub-list { list-style: none; margin: 0; padding: 0; display: flex; gap: 24px;}
.sub-list .sub-item a { color:#262626; font-family: 'Montserrat', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 16px; letter-spacing: 0.2px; text-decoration: none; transition: all 0.5s; border-bottom: 2px solid transparent; }
.sub-list .sub-item .active { color:#D0021B; font-family: 'Montserrat', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 16px; letter-spacing: 0.2px; text-decoration: none; transition: all 0.5s; border-bottom: 2px solid transparent; }
.sub-list .sub-item a:hover { color:#D0021B;}

.bras-btn { display: none;}
.header-overlay { display: none;}
.search-box { display: none;}
.search-filter { display: none;}

@media(max-width: 768px) {
    .sub-header { display: flex;}
    header { padding: 15px 22px; border-bottom: transparent;}
    .header-list { display: flex; flex-direction: column; position: fixed; background-color: #fff; bottom: 0; top: 0; z-index: 1000; padding: 20px 30px; width: 250px; transition: ease 0.5s;}
    .sub-list { display: none;}
    .bras-btn { display: block;}
    .social-media-list { gap: 15px; }
    /* .social-media-list .social-item:nth-child(1){ display: none;} */
    /* .social-media-list .social-item:nth-child(3){ display: none;} */
    .sub-header { box-shadow: unset; justify-content: unset; padding: 11px 22px; gap: 12px;}
    .header-overlay { position: fixed; z-index: 999; top: 0; right: 0; bottom: 0; background-color: #000; opacity: 0.4; display: block; transition: ease 0.5s;}
    .search-box { display: flex; border-radius: 8px; background: #F6F6F6; width: 100%; padding: 15px 17px; gap: 12px;}
    .search-box .search-input { border: none; outline: none; color: rgba(130, 130, 130, 0.70); font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal; background-color: transparent;}
    .search-filter { display: block;}
}


.sub-list- { display: none; flex-direction: column; top: 30px; position: absolute; background-color: #000; width:200px; border-radius: 6px; z-index: 10; padding: 10px 0px;}
.header-list .list-item:hover .sub-list- { display: flex;}
.sub-list- .sub-list-item { padding: 10px 20px;}
.sub-list- .sub-list-item a { color:#fff; font-family: 'Montserrat', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 16px; letter-spacing: 0.2px; text-decoration: none; transition: all 0.5s; border-bottom: 2px solid transparent; padding: 0;}
.sub-list- .sub-list-item a:hover { border-bottom: 2px solid #fff;}

.sub-list- .sub-list-item .t-list { display: none; position: absolute; width: 200px; background-color: #000; border-radius: 6px; padding: 10px 20px; right: -200px; top: 40px;}
.sub-list- .sub-list-item:hover .t-list { display: flex; flex-direction: column; position: absolute; right: 0; width: 200px; background-color: #000; border-radius: 6px; padding: 10px 20px; right: -200px; top: 0;}


@media(max-width: 768px) {
    .sub-list- { display: none; flex-direction: column; top: unset; position: relative; background-color: unset; width: unset; border-radius: unset; z-index: 10; padding: 10px 0px;}
    .sub-list- .sub-list-item a { color:#000; }
    .sub-list- .sub-list-item .t-list { display: none; position: unset !important; width: 100% !important; background-color: #FFF !important; border-radius: 6px; padding: 10px 20px; right: unset; top: unset;}
}